import { client } from "@/shared/utils/oneDeskGraphqlFactory";
import { getCatalogServicesByCriteriaPaginatedQuery, searchCatalogService, getCatalogServiceByIdQuery } from "@/graphql/query/catalog-service.query.graphql";
import { createCatalogServiceMutation, updateCatalogServiceMutation, deleteCatalogServiceMutation } from "@/graphql/mutation/catalog-service.mutation.graphql";
const searchCatalogServiceList = async (input) => {
    try {
        if (!client) {
            throw new Error("Client is not available.");
        }
        const response = await client.query({
            query: searchCatalogService,
            variables: {
                input: input,
            },
            fetchPolicy: "cache-first",
            errorPolicy: "all",
        });
        return response.data.filterManyCatalogServices;
    }
    catch (error) {
        console.error(error);
        throw error;
    }
};
const getCatalogServicesByCriteriaPaginated = async (search, pageNumber, pageSize) => {
    try {
        if (!client) {
            throw new Error("Client is not available.");
        }
        const response = await client.query({
            query: getCatalogServicesByCriteriaPaginatedQuery,
            variables: {
                search,
                pageNumber,
                pageSize
            },
            fetchPolicy: "no-cache",
            errorPolicy: "all"
        });
        return response.data.catalogServicesByCriteriaPaginated;
    }
    catch (error) {
        console.error(error);
        throw error;
    }
};
const getCatalogServiceById = async (id) => {
    try {
        if (!client) {
            throw new Error("Client is not available.");
        }
        const response = await client.query({
            query: getCatalogServiceByIdQuery,
            variables: {
                id,
            },
            fetchPolicy: "no-cache",
            errorPolicy: "all"
        });
        return response.data.catalogServiceById;
    }
    catch (error) {
        console.error(error);
        throw error;
    }
};
const addCatalogService = async (input) => {
    await upsertCatalogService(input, createCatalogServiceMutation);
};
const updateCatalogService = async (input) => {
    await upsertCatalogService(input, updateCatalogServiceMutation);
};
const upsertCatalogService = async (input, mutation) => {
    try {
        if (!client) {
            throw new Error("Client is not available.");
        }
        const response = await client.mutate({
            mutation: mutation,
            variables: {
                input: input
            },
            update: (apolloClientStore, { data }) => {
                return data;
            }
        });
        return response;
    }
    catch (error) {
        console.error(error);
        throw error;
    }
};
const deleteCatalogService = async (catalogServiceId) => {
    try {
        if (!client) {
            throw new Error("Client is not available.");
        }
        const response = await client.mutate({
            mutation: deleteCatalogServiceMutation,
            variables: {
                catalogServiceId
            }
        });
        return response;
    }
    catch (error) {
        throw new Error(error.networkError?.result?.errors?.[0].message);
    }
};
export { getCatalogServiceById, searchCatalogServiceList, getCatalogServicesByCriteriaPaginated, updateCatalogService, addCatalogService, deleteCatalogService };

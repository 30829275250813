import { MutationTypes } from "@/store/modules/reference-values/mutation-types";
import { ActionTypes } from "@/store/modules/reference-values/action-types";
export const actions = {
    [ActionTypes.SET_REFERENCE_VALUES]({ commit }, payload) {
        commit(MutationTypes.SET_REFERENCE_VALUES, payload);
    },
    [ActionTypes.GET_REFERENCE_VALUES]({ commit }, cle) {
        return commit(MutationTypes.GET_REFERENCE_VALUES, cle);
    },
};

const categoryColumns = [
    {
        field: "title",
        header: "Title",
        type: "string",
        hasSlot: false,
    },
    {
        field: "serviceTitle",
        header: "Service",
        type: "string",
        hasSlot: false,
    },
    {
        field: "avatar",
        header: "Avatar",
        type: "string",
        hasSlot: true,
    },
    {
        field: "actions",
        header: "Actions",
        type: "string",
        hasSlot: true,
    },
];
export { categoryColumns };

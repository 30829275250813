const brandColumns = [
    {
        field: "brandId",
        header: "Id",
        type: "string",
        hasSlot: false,
    },
    {
        field: "displayBrand",
        header: "Display Name",
        type: "string",
        hasSlot: false,
    },
    {
        field: "formattedBrand",
        header: "Formatted Name",
        type: "string",
        hasSlot: false,
    },
    {
        field: "logo",
        header: "Logo",
        type: "string",
        hasSlot: true,
    },
    {
        field: "actions",
        header: "Actions",
        type: "string",
        hasSlot: true,
    },
];
export { brandColumns };

import { store } from "@/store";
import * as MSAL from "@azure/msal-browser";
import Cookies from "js-cookie";
/* eslint-disable */
export default {
    install: (app, Secrets) => {
        // Plugin code goes here
        const msalConfig = {
            auth: {
                clientId: Secrets.MSAL.clientId, //"55ef3283-6052-419d-81d8-9aa3bf524a1e",
                authority: Secrets.MSAL.authority, //"https://login.microsoftonline.com/e4e1abd9-eac7-4a71-ab52-da5c998aa7ba",
                redirectUri: Secrets.MSAL.redirectUri, //"http://localhost:4000",
                postLogoutRedirectUri: Secrets.MSAL.redirectUri, //"http://localhost:4000",
                tenantId: Secrets.MSAL.tenantId, //,
            },
            cache: {
                cacheLocation: "localStorage",
            },
        };
        const tokenRequest = {
            scopes: [Secrets.MSAL.applicationIdUri + "/user_access"],
        };
        const msalApp = new MSAL.PublicClientApplication(msalConfig);
        const AuthService = {
            logout: async (userName) => {
                const userAccount = msalApp.getAccountByUsername(userName);
                const logoutRequest = {
                    account: userName ? userAccount : null,
                    postLogoutRedirectUri: Secrets.MSAL.redirectUri,
                };
                try {
                    Cookies.remove('cookieStore');
                    localStorage.removeItem('myLocalStore');
                    sessionStorage.clear();
                    return await msalApp.logoutRedirect(logoutRequest);
                }
                catch (error) {
                    console.log(error);
                }
            },
            login: async () => {
                // Remove active interaction which blocks the parent page from invoking any redirect to the B2C sign in page.
                sessionStorage.removeItem('msal.interaction.status');
                const loginRequestWithPrompt = {
                    ...tokenRequest,
                    prompt: "select_account",
                };
                try {
                    const loginResponse = await msalApp.loginPopup(loginRequestWithPrompt);
                    return loginResponse;
                }
                catch (error) {
                    console.log(error);
                    throw error;
                }
            },
            refreshToken: async () => {
                const userName = store.getters.getUserName;
                const account = msalApp.getAccountByUsername(userName.toLowerCase()) ||
                    undefined;
                const silentTokenRequest = {
                    ...tokenRequest,
                    account: account,
                };
                try {
                    const tokenResponse = await msalApp.acquireTokenSilent(silentTokenRequest);
                    return tokenResponse;
                }
                catch (error) {
                    console.log(error);
                    throw error;
                }
            },
            refreshTokeExplicitly: async () => {
                const userName = store.getters.getUserName;
                const account = msalApp.getAccountByUsername(userName.toLowerCase()) ||
                    undefined;
                const tokenPopupRequest = {
                    ...tokenRequest,
                    account: account,
                    prompt: "login",
                };
                try {
                    const tokenResponse = await msalApp.acquireTokenPopup(tokenPopupRequest);
                    return tokenResponse.accessToken;
                }
                catch (error) {
                    console.log(error);
                    throw error;
                }
            },
        };
        app.config.globalProperties.$authService = AuthService;
        app.provide("authService", AuthService);
    },
};

import { client } from "@/shared/utils/oneDeskGraphqlFactory";
import { getUserClaimsQuery, userCanPerformTestsOnWebsiteQuery } from "@/graphql/query/claims.query.graphql";
import { addUserClaimsMutation, purgeUserClaimsMutation, deleteClaimMutation, requestAccessToRestrictedFeaturesMutation } from "@/graphql/mutation/claims.mutation.graphql";
const getUserClaims = async (userId) => {
    try {
        if (!client) {
            throw new Error("Client is not available.");
        }
        const response = await client.query({
            query: getUserClaimsQuery,
            variables: {
                userId: userId,
            },
            fetchPolicy: 'network-only',
        });
        return response.data.userClaims;
    }
    catch (error) {
        console.error(error);
        throw error;
    }
};
const purgeUserClaims = async (userId) => {
    try {
        if (client) {
            const response = await client.mutate({
                mutation: purgeUserClaimsMutation,
                variables: {
                    userId: userId,
                },
                update: (_, { data }) => {
                    return data;
                },
            });
            return response;
        }
    }
    catch (error) {
        console.error(error);
        throw error;
    }
};
const addUserClaims = async (userId, claimType, claimValues) => {
    try {
        if (client) {
            const response = await client.mutate({
                mutation: addUserClaimsMutation,
                variables: {
                    input: {
                        userId,
                        claimType,
                        claimValues,
                    },
                },
                update: (_, { data }) => {
                    return data.claims;
                },
            });
            return response;
        }
    }
    catch (error) {
        console.error(error);
        throw error;
    }
};
const deleteClaim = async (claimId) => {
    try {
        if (client) {
            const response = await client.mutate({
                mutation: deleteClaimMutation,
                variables: {
                    claimId,
                },
                update: (_, { data }) => {
                    return data.deleteClaim;
                },
            });
            return response;
        }
    }
    catch (error) {
        console.error(error);
        throw error;
    }
};
const requestAccessToRestrictedFeatures = async (userId, claimType, claimValue, reason) => {
    try {
        if (client) {
            const response = await client.mutate({
                mutation: requestAccessToRestrictedFeaturesMutation,
                variables: {
                    input: {
                        userId,
                        claimType: claimType,
                        claimValues: [claimValue],
                        reason,
                    },
                }
            });
            return response;
        }
    }
    catch (error) {
        console.error(error);
        throw error;
    }
};
const userCanPerformTestsOnWebsite = async (userId, siteName) => {
    try {
        if (client) {
            const response = await client.query({
                query: userCanPerformTestsOnWebsiteQuery,
                variables: {
                    userId,
                    siteName,
                },
                fetchPolicy: 'network-only',
            });
            return response.data.userCanPerformTestsOnWebsite;
        }
    }
    catch (error) {
        console.error(error);
        throw error;
    }
};
export { getUserClaims, addUserClaims, purgeUserClaims, deleteClaim, requestAccessToRestrictedFeatures, userCanPerformTestsOnWebsite };

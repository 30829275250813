import { client } from "@/shared/utils/oneDeskGraphqlFactory";
import { getAllUsersPaginatedQuery, getUserInfoQuery } from "@/graphql/query/users.query.graphql";
const getAllUsersPaginated = async (pageNumber, pageSize, search) => {
    try {
        if (!client) {
            throw new Error("Client is not available.");
        }
        const response = await client.query({
            query: getAllUsersPaginatedQuery,
            fetchPolicy: "network-only",
            variables: {
                pageNumber,
                pageSize,
                search,
            },
        });
        return response.data.allUsersPaginated;
    }
    catch (error) {
        console.error(error);
        throw error;
    }
};
const getUserInfo = async () => {
    try {
        if (!client) {
            throw new Error("Client is not available.");
        }
        const response = await client.query({
            query: getUserInfoQuery,
            fetchPolicy: "network-only",
        });
        return response.data.userInfo;
    }
    catch (error) {
        console.error(error);
        throw error;
    }
};
export { getAllUsersPaginated, getUserInfo };

import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue";
const _hoisted_1 = { class: "website-selector" };
const _hoisted_2 = { key: 0 };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_OneDeskMultiSelect = _resolveComponent("OneDeskMultiSelect");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_OneDeskMultiSelect, {
            modelValue: _ctx.selected,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.selected) = $event)),
            options: _ctx.distinctDivisionList,
            "linera-label": false,
            "option-label": "value",
            "option-value": "value",
            "force-selection": "",
            filter: _ctx.filter,
            "show-counter": true,
            placeholder: _ctx.placeholder,
            loading: _ctx.loading,
            onChange: _ctx.onChange
        }, {
            label: _withCtx(() => [
                (_ctx.hasLabelSlot)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                        _renderSlot(_ctx.$slots, "label")
                    ]))
                    : _createCommentVNode("", true)
            ]),
            _: 3
        }, 8, ["modelValue", "options", "filter", "placeholder", "loading", "onChange"])
    ]));
}

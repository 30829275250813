const cardColumns = [
    {
        field: "title",
        header: "Title",
        type: "string",
        hasSlot: false,
    },
    {
        field: "categoryTitle",
        header: "Category",
        type: "string",
        hasSlot: false,
    },
    {
        field: "audience",
        header: "Audience",
        type: "string",
        hasSlot: true,
    },
    {
        field: "scope",
        header: "Scope",
        type: "string",
        hasSlot: true,
    },
    {
        field: "imageUri",
        header: "Icon",
        type: "string",
        hasSlot: true,
    },
    {
        field: "actions",
        header: "Actions",
        type: "string",
        hasSlot: true,
    },
];
export { cardColumns };

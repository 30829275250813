<template>
  <div class="layout-footer">
    <h5 class="font-bold footer-copyright">L’Oréal</h5>
  </div>
</template>

<script setup lang="ts">
</script>

<style lang="scss" scoped>
.footer-copyright {
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: right;
  color: #3a5161;
  height: 1.5rem;
}
</style>

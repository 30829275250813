import { ActionTypes } from "./action-types";
import { MutationTypes } from "./mutation-types";
import router from "@/router";
import { store } from "@/store";
import { AllActionTypes } from "@/store/action-types";
import { getUserInfo } from "@/repositories/users.repository";
export const actions = {
    async [ActionTypes.LOGIN]({ commit }, auth) {
        auth
            .login()
            .then((loginResponse) => {
            commit(MutationTypes.SET_AUTHENTIFIED, true);
            commit(MutationTypes.SET_ACCESS_TOKEN, loginResponse.accessToken);
            store.dispatch(AllActionTypes.GET_VALID_USER, loginResponse.accessToken);
            commit(MutationTypes.SET_USERNAME, loginResponse.account?.username);
            router.push({ path: "/home" });
        })
            .catch((error) => {
            console.log(error);
            commit(MutationTypes.RESET_USER_STATE);
            localStorage.clear();
        });
    },
    [ActionTypes.LOGOUT]({ commit }, auth) {
        const userName = store.getters.getUserName;
        commit(MutationTypes.RESET_USER_STATE);
        auth
            .logout(userName)
            .catch((error) => {
            console.log(error);
        });
    },
    [ActionTypes.RESET_USER]({ commit }) {
        commit(MutationTypes.RESET_USER_STATE);
        localStorage.clear();
    },
    async [ActionTypes.REFRESH_TOKEN]({ commit }, auth) {
        if (store.getters.getUserName != undefined && store.getters.getUserName != "" && store.getters.isAuthentificated) {
            auth
                .refreshToken()
                .then((loginResponse) => {
                commit(MutationTypes.SET_ACCESS_TOKEN, loginResponse.accessToken);
                store.dispatch(AllActionTypes.GET_VALID_USER, loginResponse.accessToken);
            })
                .catch((error) => {
                console.log(error);
                auth
                    .refreshTokeExplicitly()
                    .then((loginResponse) => {
                    commit(MutationTypes.SET_ACCESS_TOKEN, loginResponse);
                })
                    .catch((error) => {
                    console.log(error);
                    const userName = store.getters.getUserName;
                    commit(MutationTypes.RESET_USER_STATE);
                    localStorage.clear();
                    auth.logout(userName);
                });
            });
        }
        else {
            commit(MutationTypes.RESET_USER_STATE);
            localStorage.clear();
            auth.logout("");
        }
    },
    async [ActionTypes.GET_VALID_USER]({ commit }) {
        return new Promise((resolve, reject) => {
            getUserInfo()
                .then(async function (resp) {
                const account = {
                    id: resp.id,
                    firstname: resp.firstName,
                    lastname: resp.lastName,
                    displayname: resp.displayName,
                    accountname: resp.account,
                    pictureid: resp.pictureFilename,
                    picture: "",
                    adminRoles: resp.adminRoles,
                };
                commit(MutationTypes.SET_ACCOUNT, account);
                resolve();
            })
                .catch((error) => {
                console.log(error);
                reject();
            });
        });
    },
};

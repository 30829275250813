import { client } from "@/shared/utils/oneDeskGraphqlFactory";
import { getTagsByCriteriaQuery, getIsValidTagQuery } from "@/graphql/query/tags.query.graphql";
import { createTagMutation, deleteTagMutation } from "@/graphql/mutation/tags.mutation.graphql";
const getTagsByCriteria = async (search) => {
    try {
        if (!client) {
            throw new Error("Client is not available.");
        }
        const response = await client.query({
            query: getTagsByCriteriaQuery,
            variables: {
                search,
            },
            fetchPolicy: "no-cache",
        });
        return response.data.tagsByCriteria;
    }
    catch (error) {
        console.error(error);
        throw error;
    }
};
const createTag = async (label) => {
    try {
        if (!client) {
            throw new Error("Client is not available.");
        }
        const response = await client.mutate({
            mutation: createTagMutation,
            variables: {
                label
            }
        });
        return response;
    }
    catch (error) {
        console.error(error);
        throw error;
    }
};
const deleteTag = async (id) => {
    try {
        if (!client) {
            throw new Error("Client is not available.");
        }
        const response = await client.mutate({
            mutation: deleteTagMutation,
            variables: {
                id
            }
        });
        return response;
    }
    catch (error) {
        console.error(error);
        throw error;
    }
};
const getIsValidTag = async (label) => {
    try {
        if (!client) {
            throw new Error("Client is not available.");
        }
        const response = await client.query({
            query: getIsValidTagQuery,
            variables: {
                label
            },
            fetchPolicy: "no-cache",
        });
        return response.data.isValidTag;
    }
    catch (error) {
        console.error(error);
        throw error;
    }
};
export { getTagsByCriteria, getIsValidTag, createTag, deleteTag };

import { client } from "@/shared/utils/oneDeskGraphqlFactory";
import { getAllMarketsPaginatedQuery } from "@/graphql/query/market.query.graphql";
import { updateMarketMutation } from "@/graphql/mutation/market.mutation.graphql";
const getAllMarketsPaginated = async (pageNumber, pageSize, search) => {
    try {
        if (!client) {
            throw new Error("Client is not available.");
        }
        const response = await client.query({
            query: getAllMarketsPaginatedQuery,
            fetchPolicy: "network-only",
            variables: { pageNumber, pageSize, search }
        });
        return response.data.allMarketsPaginated;
    }
    catch (error) {
        console.error(error);
        throw error;
    }
};
const updateMarket = async (input) => {
    try {
        if (!client) {
            throw new Error("Client is not available.");
        }
        const response = await client.mutate({
            mutation: updateMarketMutation,
            variables: {
                input: input
            },
            update: (apolloClientStore, { data }) => {
                return data.market;
            }
        });
        return response;
    }
    catch (error) {
        console.log(error);
        throw error;
    }
};
export { getAllMarketsPaginated, updateMarket };

import { computed, defineComponent, ref, watch } from "vue";
import Warning from "@/assets/warning.svg";
import Clock from "@/assets/clock.svg";
import OneDeskWarningBlock from "@/components/atoms/OneDeskWarningBlock.vue";
import OneDeskButton from "@/components/atoms/OneDeskButton.vue";
import OneDeskInput from "@/components/atoms/OneDeskInput.vue";
import Checkbox from "primevue/checkbox";
import Editor from "primevue/editor";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import Button from "primevue/button";
import { Roles } from "@/entities/user.entity";
export default defineComponent({
    name: "WPDescription",
    components: {
        OneDeskWarningBlock,
        OneDeskButton,
        OneDeskInput,
        Editor,
        Checkbox,
        Button,
    },
    props: {
        testPage: {
            type: Object,
            default: () => { },
            required: true,
        },
        isModal: {
            type: Boolean,
            default: false,
            required: false,
        },
    },
    emits: ["enable-edit", "update-test-page"],
    setup(props, context) {
        const store = useStore();
        const route = useRoute();
        const editModeEnabled = ref(false);
        const updatedTitle = ref("");
        const updatedTimeDuration = ref("");
        const updatedActionImpact = ref("");
        const updatedContent = ref("");
        const updatedIsEnabled = ref(true);
        const label_1 = ref("");
        const link_1 = ref("");
        const label_2 = ref("");
        const link_2 = ref("");
        const label_3 = ref("");
        const link_3 = ref("");
        const label_4 = ref("");
        const link_4 = ref("");
        const label_5 = ref("");
        const link_5 = ref("");
        const isAdmin = computed(() => store.getters.adminRoles.includes(Roles.SystemAdmin));
        const enableEditPage = (enabled) => {
            context.emit("enable-edit", enabled);
            editModeEnabled.value = enabled;
            if (enabled) {
                updatedTitle.value = props.testPage.title;
                updatedTimeDuration.value = props.testPage.timeDuration;
                updatedActionImpact.value = props.testPage.actionImpact;
                updatedContent.value = props.testPage.description;
                updatedIsEnabled.value = props.testPage.enabled;
                setFormLinks(props.testPage.resources);
            }
        };
        const cancelEditPage = () => {
            context.emit("enable-edit", false);
            emptyFields();
        };
        const setFormLinks = (links) => {
            if (links) {
                label_1.value = links[0]?.label ?? null;
                link_1.value = links[0]?.link ?? null;
                label_2.value = links[1]?.label ?? null;
                link_2.value = links[1]?.link ?? null;
                label_3.value = links[2]?.label ?? null;
                link_3.value = links[2]?.link ?? null;
                label_4.value = links[3]?.label ?? null;
                link_4.value = links[3]?.link ?? null;
                label_5.value = links[4]?.label ?? null;
                link_5.value = links[4]?.link ?? null;
            }
        };
        const updateTestPage = () => {
            editModeEnabled.value = false;
            const resources = buildResources();
            const data = {
                id: props.testPage.id,
                slug: props.testPage.slug,
                title: updatedTitle.value,
                timeDuration: updatedTimeDuration.value,
                actionImpact: updatedActionImpact.value,
                description: updatedContent.value,
                enabled: updatedIsEnabled.value,
                resources: JSON.stringify(resources),
            };
            context.emit("update-test-page", data);
            emptyFields();
        };
        const buildResources = () => {
            const links = [];
            for (let i = 1; i <= 5; i++) {
                const label = eval(`label_${i}.value`);
                const link = eval(`link_${i}.value`);
                if (label !== "" && label !== null && link !== "" && link !== null) {
                    links.push({ label, link });
                }
            }
            return links;
        };
        const emptyFields = () => {
            editModeEnabled.value = false;
            updatedTitle.value = "";
            updatedTimeDuration.value = "";
            updatedActionImpact.value = "";
            updatedContent.value = "";
            updatedIsEnabled.value = props.testPage.enabled;
            setFormLinks([]);
        };
        watch(() => route.path, () => cancelEditPage());
        return {
            Warning,
            Clock,
            store,
            updatedTitle,
            updatedTimeDuration,
            updatedActionImpact,
            updatedContent,
            editModeEnabled,
            updatedIsEnabled,
            label_1,
            link_1,
            label_2,
            link_2,
            label_3,
            link_3,
            label_4,
            link_4,
            label_5,
            link_5,
            isAdmin,
            cancelEditPage,
            enableEditPage,
            updateTestPage,
        };
    },
});

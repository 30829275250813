export var RequestTaskType;
(function (RequestTaskType) {
    RequestTaskType["Website"] = "WEBSITE";
    RequestTaskType["Market"] = "MARKET";
    RequestTaskType["Brand"] = "BRAND";
})(RequestTaskType || (RequestTaskType = {}));
export var TaskType;
(function (TaskType) {
    TaskType["All"] = "all";
    TaskType["Wp1"] = "WP1";
    TaskType["Wp4"] = "WP4";
    TaskType["Wp6"] = "WP6";
    TaskType["Wp8"] = "WP8";
})(TaskType || (TaskType = {}));

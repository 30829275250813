const marketColumns = [
    {
        field: "market",
        header: "market",
        type: "string",
        hasSlot: true,
    },
    {
        field: "id",
        header: "Code",
        type: "string",
        hasSlot: false,
    },
    {
        field: "displayMarket",
        header: "Display name",
        type: "string",
        hasSlot: false,
    },
    {
        field: "actions",
        header: "Actions",
        type: "string",
        hasSlot: true,
    },
];
export { marketColumns };

import { getters } from "./getters";
import { actions } from "./actions";
import { mutations } from "./mutations";
import { state } from "./state";
// Module
const tasks = {
    state,
    getters,
    mutations,
    actions,
};
export default tasks;

import VueApollo from "vue-apollo";
import { ApolloClient, InMemoryCache, ApolloLink, createHttpLink } from "@apollo/client/core";
import { AllActionTypes } from "@/store/action-types";
import authService from "@/plugins/Authenticator";
function getClientFactory(store) {
    const graphQlApiUrl = store.getters.getGraphQLApiUrl;
    const httpOption = { uri: graphQlApiUrl, credentials: "omit" };
    const link = createHttpLink(httpOption);
    const authLink = new ApolloLink((operation, forward) => {
        const accessToken = store.getters.getAccessToken;
        operation.setContext({
            headers: {
                authorization: accessToken ? "Bearer " + accessToken : "",
            },
        });
        return forward(operation).map((response) => {
            try {
                if (response.errors && response.errors[0].extensions && response.errors[0].extensions.code == "authorization") {
                    store.dispatch(AllActionTypes.REFRESH_TOKEN, authService);
                    const access_token = store.getters.getAccessToken;
                    new ApolloLink((operation, forward) => {
                        operation.setContext({
                            headers: {
                                authorization: accessToken ? "Bearer " + access_token : "",
                            },
                        });
                        return forward(operation).map((response) => {
                            if (response.errors && response.errors[0].extensions && response.errors[0].extensions.code == "authorization") {
                                store.dispatch(AllActionTypes.LOGOUT, authService);
                            }
                            return response;
                        });
                    });
                }
            }
            catch (err) {
                console.log(err);
            }
            return response;
        });
    });
    const cache = new InMemoryCache({});
    return new ApolloClient({
        link: authLink.concat(link),
        cache: cache,
        connectToDevTools: true,
    });
}
let vueApolloProvider = {};
let client = null;
const createGraphQLProvider = function (store) {
    client = getClientFactory(store);
    vueApolloProvider = new VueApollo({
        defaultClient: client,
    });
    //window.provider = vueApolloProvider;
    return vueApolloProvider;
};
export { createGraphQLProvider, vueApolloProvider, client };

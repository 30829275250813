import { client } from "@/shared/utils/oneDeskGraphqlFactory";
import { getTestPageBySlugQuery } from "@/graphql/query/wp.query.graphql";
import { updateTestPageMutation } from "@/graphql/mutation/wp.mutation.graphql";
import { launchWebPageTestMutation } from "@/graphql/mutation/wp.mutation.graphql";
import { checkWebPageTestStatusQuery } from "@/graphql/query/wp.query.graphql";
const getTestBySlug = async (slug) => {
    try {
        if (!client) {
            throw new Error("Client is not available.");
        }
        const response = await client.query({
            query: getTestPageBySlugQuery,
            variables: {
                slug: slug,
            },
            fetchPolicy: "cache-first",
        });
        return {
            ...response.data.testPageBySlug,
            resources: getResources(response.data.testPageBySlug)
        };
    }
    catch (error) {
        console.error(error);
        throw error;
    }
};
const updateTestPage = async (input) => {
    try {
        if (!client) {
            throw new Error("Client is not available.");
        }
        await client.mutate({
            mutation: updateTestPageMutation,
            variables: {
                input: input,
            },
        });
    }
    catch (error) {
        console.error(error);
        throw error;
    }
};
const getResources = (testPage) => {
    if (testPage.resources.length > 0) {
        let formatedString = testPage.resources.replace("[", "");
        formatedString = formatedString.replace("]", "");
        const objectPattern = /{[^}]*}/g;
        const matches = formatedString.match(objectPattern);
        if (matches) {
            return matches.map((match) => {
                return new Function("return " + match)();
            });
        }
        else
            return [];
    }
    else
        return [];
};
const launchWebPageTest = async (parameters, wpType) => {
    try {
        if (!client) {
            throw new Error("Client is not available.");
        }
        const response = await client.mutate({
            mutation: launchWebPageTestMutation,
            variables: {
                parameters,
                wpType,
            },
        });
        return response.data.launchWebPageTest;
    }
    catch (error) {
        console.error(error);
        throw error;
    }
};
const checkWebPageTestStatus = async (testId) => {
    try {
        if (!client) {
            throw new Error("Client is not available.");
        }
        const response = await client.query({
            query: checkWebPageTestStatusQuery,
            variables: {
                testId,
            },
            fetchPolicy: "network-only",
        });
        return response.data.checkWebPageTestStatus;
    }
    catch (error) {
        console.error(error);
        throw error;
    }
};
export { getTestBySlug, updateTestPage, launchWebPageTest, checkWebPageTestStatus };

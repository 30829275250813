import { getters } from "./getters";
import { actions } from "./actions";
import { mutations } from "./mutations";
import { state } from "./state";
import userModule from "../user";
import tasksModule from "../tasks";
import navigationModule from "../navigation";
import referenceValuesModule from "../reference-values";
// Modules
const modules = {
    userModule,
    tasksModule,
    navigationModule,
    referenceValuesModule,
};
const root = {
    state,
    getters,
    mutations,
    actions,
    modules,
};
export default root;

export const state = {
    accessToken: undefined,
    isAuthentificated: false,
    userName: undefined,
    account: {
        id: "",
        firstname: "",
        lastname: "",
        displayname: "",
        accountname: "",
        pictureid: "",
        picture: "",
        adminRoles: [],
    },
};

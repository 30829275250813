const serviceColumns = [
    {
        field: "title",
        header: "Title",
        type: "string",
        hasSlot: false,
    },
    {
        field: "actions",
        header: "Actions",
        type: "string",
        hasSlot: true,
    },
];
export { serviceColumns };

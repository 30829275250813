import { client } from "@/shared/utils/oneDeskGraphqlFactory";
import { getCatalogCategoriesByCriteriaPaginatedQuery, getCatalogCategoryByIdQuery } from "@/graphql/query/catalog-category.query.graphql";
import { updateCatalogCategoryMutation, createCatalogCategoryMutation, deleteCatalogCategoryMutation } from "@/graphql/mutation/catalog-category.mutation.graphql";
const getCatalogCategoriesByCriteriaPaginated = async (search, pageNumber, pageSize) => {
    try {
        if (!client) {
            throw new Error("Client is not available.");
        }
        const response = await client.query({
            query: getCatalogCategoriesByCriteriaPaginatedQuery,
            variables: {
                search,
                pageNumber,
                pageSize
            },
            fetchPolicy: "no-cache",
            errorPolicy: "all"
        });
        return response.data.catalogCategoriesByCriteriaPaginated;
    }
    catch (error) {
        console.error(error);
        throw error;
    }
};
const getCatalogCategoryById = async (id) => {
    try {
        if (!client) {
            throw new Error("Client is not available.");
        }
        const response = await client.query({
            query: getCatalogCategoryByIdQuery,
            variables: {
                id,
            },
            fetchPolicy: "no-cache",
            errorPolicy: "all"
        });
        return response.data.catalogCategoryById;
    }
    catch (error) {
        console.error(error);
        throw error;
    }
};
const addCatalogCategory = async (input) => {
    await upsertCatalogCategory(input, createCatalogCategoryMutation);
};
const updateCatalogCategory = async (input) => {
    await upsertCatalogCategory(input, updateCatalogCategoryMutation);
};
const upsertCatalogCategory = async (input, mutation) => {
    try {
        if (!client) {
            throw new Error("Client is not available.");
        }
        const response = await client.mutate({
            mutation: mutation,
            variables: {
                input: input
            },
            update: (apolloClientStore, { data }) => {
                return data;
            },
        });
        return response;
    }
    catch (error) {
        console.log(error);
        throw error;
    }
};
const deleteCatalogCategory = async (catalogCategoryId) => {
    try {
        if (!client) {
            throw new Error("Client is not available.");
        }
        const response = await client.mutate({
            mutation: deleteCatalogCategoryMutation,
            variables: {
                catalogCategoryId
            }
        });
        return response;
    }
    catch (error) {
        throw new Error(error.networkError?.result?.errors?.[0].message);
    }
};
export { getCatalogCategoryById, getCatalogCategoriesByCriteriaPaginated, updateCatalogCategory, addCatalogCategory, deleteCatalogCategory };

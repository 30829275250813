export const getters = {
    getSelectedNavLink: (state) => {
        return state.selectedNavLink;
    },
    getInventorySearch: (state) => {
        return state.inventorySearch;
    },
    getDisplayFilters: (state) => {
        return state.displayFilters;
    },
};

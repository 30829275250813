<template>
  <div class="wp-warning">
    <i class="pi pi-exclamation-triangle" style="font-size: 2rem; margin-right: 20px; color: var(--purple-500)"></i>
    <slot name="content"></slot>
  </div>
</template>

<script setup lang="ts">
</script>

<style lang="scss">
.wp-warning {
  margin-bottom: 30px;
  background: var(--purple-100);
  border-radius: 4px;
  padding: 14px 30px 12px 30px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: center;
  .warning-icon-wrapper {
    margin-right: 20px;
  }
}
</style>

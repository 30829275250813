import { useToast } from "primevue/usetoast";
export default function useToastNotify() {
    const toast = useToast();
    const notify = (summary, detail, severity, life = 5000) => {
        toast.add({
            severity: severity,
            summary: summary,
            detail: detail,
            group: "standard",
            life: life,
        });
    };
    return notify;
}

const siteProtocolPrefixesList = [
    { value: "https://" },
    { value: "http://www." },
    { value: "https://www." },
    { value: "http://" },
];
const divisionList = [
    { value: "CORP", label: "Corporate" },
    { value: "LPD", label: "L'Oréal Professional Products Division" },
    { value: "LDB", label: "L'Oréal Luxe Division" },
    { value: "?", label: "Unknown" },
    { value: "CPD", label: "Consumer Products Division" },
    { value: "ACD", label: "Active Cosmetics Division" },
    { value: "AUTO", label: "Unknown" },
    { value: "PPD", label: "Professional Products Division" },
];
const environmentTypeList = [
    { value: "development" },
    { value: "integration" },
    { value: "quality" },
    { value: "staging" },
    { value: "uat" },
    { value: "preproduction" },
    { value: "production" },
];
const siteTypesList = [
    { value: "B2B Site" },
    { value: "B2B Site - External" },
    { value: "B2C Brand Site" },
    { value: "B2C Content Site" },
    { value: "B2C Dedicated Mobile Site" },
    { value: "B2C INTL Dispatch" },
    { value: "B2C INTL Minisite" },
    { value: "B2C INTL Site" },
    { value: "B2C Minisite" },
    { value: "B2C Minisite - External" },
    { value: "B2C Salon Locator" },
    { value: "B2E Site" },
    { value: "B2E Site - External" },
    { value: "E-retailer (TMALL)" },
    { value: "E-retailer using a loreal domain" },
    { value: "Technical Instance" },
    { value: "Technical Instance - (External)" },
];
const priorityList = [
    { value: "1", displayValue: "1" },
    { value: "1B", displayValue: "1B" },
    { value: "1L", displayValue: "1L" },
    { value: "2", displayValue: "2" },
    { value: "3", displayValue: "3" },
    { value: "3L", displayValue: "3L" },
    { value: "4", displayValue: "4" },
    { value: "CLOSED", displayValue: "Closed (won't be displayed)" },
    { value: "SUSPENDED", displayValue: "Suspended (won't be displayed)" },
    { value: "TO CHALLENGE", displayValue: "To Challenge" },
    {
        value: "TO CLOSE - CLOSURE LANDING",
        displayValue: "To Close - Closure Landing (won't be displayed)",
    },
    {
        value: "TO CLOSE - ON GOING",
        displayValue: "To Close - On going (won't be displayed)",
    },
    {
        value: "TO CLOSE - VALIDATED",
        displayValue: "To Close - Validate (won't be displayed)",
    },
    { value: "TO COME", displayValue: "To Come (won't be displayed))" },
];
const zoneList = [
    { value: "_INTL", label: "International" },
    { value: "EUROPE (EE)", label: "Eastern Europe" },
    { value: "EUROPE (WE)", label: "Western Europe" },
    { value: "LATAM", label: "Latin America" },
    { value: "NORAM", label: "North America" },
    { value: "NORTH ASIA", label: "North Asia" },
    { value: "SAPMENA", label: "South Asia Pacific Middle East North Africa" },
    { value: "SUB-SAHARAN AFRICA", label: "Sub-Saharan Africa" },
    { value: "EUROPE", label: "Europe" },
];
export { siteProtocolPrefixesList, divisionList, environmentTypeList, siteTypesList, priorityList, zoneList };

const taskReportColumns = [
    {
        field: "status",
        header: "Status",
        type: "string",
        hasSlot: true,
    },
    {
        field: "user",
        header: "Made By",
        hasSlot: true,
    },
    {
        field: "created",
        header: "Date",
        type: "string",
        hasSlot: true,
    },
    {
        field: "taskType",
        header: "Type",
        type: "string",
        hasSlot: true,
    },
    {
        field: "result",
        header: "Result",
        type: "string",
        hasSlot: true,
    },
];
export { taskReportColumns };

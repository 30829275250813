import { client } from "@/shared/utils/oneDeskGraphqlFactory";
import { getAuthorizationRequestsPaginatedQuery } from "@/graphql/query/authorization-request.query.graphql";
import { approveOrRejectAuthorizationRequestMutation } from "@/graphql/mutation/authorization-request.mutation.graphql";
const getAuthorizationRequestsPaginated = async (pageNumber, pageSize, searchText, statuses) => {
    try {
        if (!client) {
            throw new Error("Client is not available.");
        }
        const response = await client.query({
            query: getAuthorizationRequestsPaginatedQuery,
            fetchPolicy: "network-only",
            variables: { pageNumber, pageSize, searchText, statuses }
        });
        return response.data.authorizationRequestsPaginated;
    }
    catch (error) {
        console.error(error);
        throw error;
    }
};
const approveOrRejectAuthorizationRequest = async (authorizationRequestId, approve) => {
    try {
        if (!client) {
            throw new Error("Client is not available.");
        }
        const response = await client.mutate({
            mutation: approveOrRejectAuthorizationRequestMutation,
            variables: { authorizationRequestId, approve }
        });
        return response.data.approveOrRejectAuthorizationRequest;
    }
    catch (error) {
        console.error(error);
        throw error;
    }
};
export { getAuthorizationRequestsPaginated, approveOrRejectAuthorizationRequest };

export const getters = {
    getAccessToken: (state) => {
        return state.accessToken || "";
    },
    getUserName: (state) => {
        return state.userName || "";
    },
    isAuthentificated: (state) => {
        return state.isAuthentificated || false;
    },
    getAccount: (state) => {
        return state.account;
    },
    adminRoles: (state) => {
        return state.account.adminRoles;
    }
};

const inventoryColumns = [
    {
        field: "siteName",
        header: "Product",
        type: "string",
        hasFilter: false,
        hasSlot: true,
    },
    {
        field: "siteType",
        header: "Type",
        type: "string",
        hasFilter: false,
        hasSlot: false,
    },
    {
        field: "audience",
        header: "Audience",
        type: "string",
        hasFilter: false,
        hasSlot: false,
    },
    {
        field: "anomalies",
        header: "Anomalies",
        type: "string",
        hasFilter: false,
        hasSlot: true,
    },
];
export { inventoryColumns };

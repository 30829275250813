import { MutationTypes } from "./mutation-types";
export const mutations = {
    [MutationTypes.SELECT_LINK](state, name) {
        state.selectedNavLink = name;
    },
    [MutationTypes.SET_INVENTORY_SEARCH](state, search) {
        state.inventorySearch = search;
    },
    [MutationTypes.SET_DISPLAY_FILTERS](state, displayFilters) {
        state.displayFilters = displayFilters;
    },
};

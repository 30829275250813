import { client } from "@/shared/utils/oneDeskGraphqlFactory";
import { getInventoryPaginated, getInventoryPublicWebsitesByBrandAndMarket, getInventoryWebsitesByCriteriaPaginated, getBackOfficeInventory } from "@/graphql/query/inventory.query.graphql";
import { getWebsiteDetailsById, getWebsiteDetailsBySlug } from "@/graphql/query/websiteById.query.graphql";
import { createInventoryWebsiteMutation, updateInventoryWebsiteMutation } from "@/graphql/mutation/inventory.mutation.graphql";
const getInventoryDataPaginated = async (pageNumber, pageSize, groupBy, includePrivate) => {
    try {
        if (!client) {
            throw new Error("Client is not available.");
        }
        const response = await client.query({
            query: getInventoryPaginated,
            variables: {
                pageNumber,
                pageSize,
                groupBy,
                includePrivate
            },
            fetchPolicy: "cache-first",
        });
        return response.data.inventoryPaginated;
    }
    catch (error) {
        console.error(error);
        throw error;
    }
};
const getInventoryWebsitesDataByCriteriaPaginated = async (pageNumber, pageSize, search, brands, markets, includePrivate = false, hasSyncIssues = false) => {
    try {
        if (!client) {
            throw new Error("Client is not available.");
        }
        const response = await client.query({
            query: getInventoryWebsitesByCriteriaPaginated,
            variables: {
                pageNumber,
                pageSize,
                search,
                markets,
                brands,
                includePrivate,
                hasSyncIssues
            },
            fetchPolicy: "network-only",
        });
        return response.data.inventoryWebsitesByCriteriaPaginated;
    }
    catch (error) {
        console.error(error);
        throw error;
    }
};
const getPublicWebsitesByBrandAndMarket = async (brand, market) => {
    try {
        if (!client) {
            throw new Error("Client is not available.");
        }
        const response = await client.query({
            query: getInventoryPublicWebsitesByBrandAndMarket,
            fetchPolicy: "cache-first",
            variables: {
                brand,
                market
            }
        });
        return response.data.inventoryPublicWebsitesByBrandAndMarket;
    }
    catch (error) {
        console.error(error);
        throw error;
    }
};
const getInventoryBackOffice = async () => {
    try {
        if (!client) {
            throw new Error("Client is not available.");
        }
        const response = await client.query({
            query: getBackOfficeInventory,
            fetchPolicy: "cache-first",
        });
        return response.data.inventory;
    }
    catch (error) {
        console.error(error);
        throw error;
    }
};
const getWebsiteDetails = async (websiteId) => {
    try {
        if (!client) {
            throw new Error("Client is not available.");
        }
        const response = await client.query({
            query: getWebsiteDetailsById,
            variables: {
                id: websiteId,
            },
            fetchPolicy: "network-only",
        });
        return response.data.websiteById;
    }
    catch (error) {
        console.error(error);
        throw error;
    }
};
const getWebsiteBySlug = async (slug) => {
    try {
        if (!client) {
            throw new Error("Client is not available.");
        }
        const response = await client.query({
            query: getWebsiteDetailsBySlug,
            variables: {
                slug
            },
            fetchPolicy: "network-only",
        });
        return response.data.websiteBySlug;
    }
    catch (error) {
        console.error(error);
        throw error;
    }
};
// Upsert WebSite
const addWebSite = async (input) => {
    await upsertWebSite(input, createInventoryWebsiteMutation);
};
const updateWebSite = async (input) => {
    await upsertWebSite(input, updateInventoryWebsiteMutation);
};
const upsertWebSite = async (input, mutation) => {
    try {
        if (!client) {
            throw new Error("Client is not available.");
        }
        const response = await client.mutate({
            mutation: mutation,
            variables: {
                input: input
            },
            update: (apolloClientStore, { data }) => {
                return data.inventory;
            }
        });
        return response;
    }
    catch (error) {
        console.log(error);
        throw error;
    }
};
export { getInventoryDataPaginated, getInventoryWebsitesDataByCriteriaPaginated, getPublicWebsitesByBrandAndMarket, getInventoryBackOffice, getWebsiteDetails, getWebsiteBySlug, updateWebSite, addWebSite };

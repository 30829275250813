export function validateEmail(value) {
    /* eslint-disable no-useless-escape*/
    const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return (!value || regex.test(value)) || "Please enter a valid email address";
}
export function validateURL(url) {
    /* eslint-disable no-useless-escape*/
    const regex = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;
    return (!url || regex.test(url)) || "Please enter a valid url address (https://example.com)";
}
export function mandatoryField(value) {
    return !!(value?.trim()) || "This field is mandatory";
}
export function maxLength(length) {
    return (value) => {
        if (!value)
            return true;
        return value.length <= length || `This field should contain less than ${length} characters`;
    };
}
export function minLength(length) {
    return (value) => {
        if (!value)
            return `This field should contain more than ${length} characters`;
        return value.length >= length || `This field should contain more than ${length} characters`;
    };
}
export function validateNumber(value) {
    return !isNaN(Number(value)) || "This field should be a number";
}
export function validatePositiveNumber(value) {
    return !isNaN(Number(value)) && Number(value) > 0 || "This field should be a positive number";
}
export function validateInteger(value) {
    return Number.isInteger(Number(value)) || "This field should be an integer";
}
export function validatePositiveInteger(value) {
    return Number.isInteger(Number(value)) && Number(value) > 0 || "This field should be a positive integer";
}
export function validateDate(value) {
    return !isNaN(Date.parse(value)) || "This field should be a date";
}
export function validatePhone(value) {
    const regex = /^\d{10}$/;
    return (!value || regex.test(value)) || "Please enter a valid phone number";
}

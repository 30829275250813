import { client } from "@/shared/utils/oneDeskGraphqlFactory";
import { getReferenceValuesQuery } from "@/graphql/query/reference-values.query.graphql";
const getReferenceValuesDataQuery = async (type) => {
    try {
        if (!client) {
            throw new Error("Client is not available.");
        }
        const response = await client.query({
            query: getReferenceValuesQuery,
            fetchPolicy: "cache-first",
            variables: {
                type: type
            }
        });
        return response.data.referenceValues;
    }
    catch (error) {
        console.error(error);
        throw error;
    }
};
export { getReferenceValuesDataQuery };

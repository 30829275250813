import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-14c00f87"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "page-container" };
const _hoisted_2 = { class: "wp-container" };
const _hoisted_3 = { class: "wp-left-block" };
const _hoisted_4 = { class: "wp-description" };
const _hoisted_5 = {
    key: 0,
    class: "wp-block"
};
const _hoisted_6 = { class: "wp-right-block" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_WPDescription = _resolveComponent("WPDescription");
    const _component_WpBlock = _resolveComponent("WpBlock");
    const _component_WpRigthBlock = _resolveComponent("WpRigthBlock");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_WPDescription, {
                        onEnableEdit: _ctx.enableEditMode,
                        onUpdateTestPage: _ctx.editTestPage,
                        testPage: _ctx.testPage,
                        "is-modal": false
                    }, null, 8, ["onEnableEdit", "onUpdateTestPage", "testPage"])
                ]),
                (!_ctx.hideWpBlock)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                        _createVNode(_component_WpBlock, {
                            "wp-type": _ctx.wpType,
                            slug: _ctx.testPage.slug
                        }, null, 8, ["wp-type", "slug"])
                    ]))
                    : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_6, [
                (!_ctx.hideWpBlock)
                    ? (_openBlock(), _createBlock(_component_WpRigthBlock, {
                        key: 0,
                        testPage: _ctx.testPage
                    }, null, 8, ["testPage"]))
                    : _createCommentVNode("", true)
            ])
        ])
    ]));
}

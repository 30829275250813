const userColumns = [
    {
        field: "displayName",
        header: "Name",
        type: "string",
        hasSlot: false,
    },
    {
        field: "account",
        header: "Email",
        hasSlot: true,
    },
    {
        field: "isAdmin",
        header: "Roles",
        hasSlot: true,
    },
    {
        field: "actions",
        header: "Actions",
        type: "string",
        hasSlot: true,
    },
];
export { userColumns };

import { computed, ref } from "vue";
export default function useFormValidation() {
    const validationStateDict = ref({});
    const invalidForm = computed(() => {
        if (Object.keys(validationStateDict.value).length === 0) {
            return true;
        }
        return Object.values(validationStateDict.value).some((v) => !v);
    });
    return { validationStateDict, invalidForm };
}

<template>
  <div class="layout-topbar layout-menu">
    <div class="layout-topbar-logo">
      <img class="one-report-logo" :src="Logo" alt="one-report-logo" @click="onLogoClick"/>
    </div>
    <div class="layout-topbar-menu">
      <OneDeskNavigation/>
    </div>
    <UserMenu/>
  </div>
</template>
<script setup>
import Logo from "@/assets/Logo-header.svg";
import OneDeskNavigation from "@/components/molecules/OneDeskNavigation.vue";
import UserMenu from "@/components/organisms/UserMenu.vue";
import {AllActionTypes} from "@/store/action-types";
import {useStore} from "vuex";

const store = useStore();

const onLogoClick = () => {
  store.dispatch(AllActionTypes.NAVIGATE_TO, "home");
};
</script>
<style lang="scss" scoped></style>

import { client } from "@/shared/utils/oneDeskGraphqlFactory";
import { getAllBrandsPaginatedQuery, getAllBrandsQuery } from "@/graphql/query/brand.query.graphql";
import { updateBrandMutation } from "@/graphql/mutation/brand.mutation.graphql";
const getAllBrandsPaginated = async (pageNumber, pageSize, search) => {
    try {
        if (!client) {
            throw new Error("Client is not available.");
        }
        const response = await client.query({
            query: getAllBrandsPaginatedQuery,
            fetchPolicy: "network-only",
            variables: { pageNumber, pageSize, search }
        });
        return response.data.allBrandsPaginated;
    }
    catch (error) {
        console.error(error);
        throw error;
    }
};
const getAllBrands = async () => {
    try {
        if (!client) {
            throw new Error("Client is not available.");
        }
        const response = await client.query({
            query: getAllBrandsQuery,
            fetchPolicy: "no-cache",
        });
        return response.data.allBrands;
    }
    catch (error) {
        console.error(error);
        throw error;
    }
};
const updateBrand = async (input) => {
    try {
        if (!client) {
            throw new Error("Client is not available.");
        }
        const response = await client.mutate({
            mutation: updateBrandMutation,
            variables: {
                input: input
            },
            update: (apolloClientStore, { data }) => {
                return data.brand;
            }
        });
        return response;
    }
    catch (error) {
        console.log(error);
        throw error;
    }
};
export { getAllBrandsPaginated, getAllBrands, updateBrand };

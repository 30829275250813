import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { store } from "./store";
import Authenticator from "./plugins/Authenticator";
import Secrets from "./onedesk-portal.usersecrets.json";
import PrimeVue from "primevue/config";
import { DefaultApolloClient } from "@vue/apollo-composable";
import { createGraphQLProvider } from "@/shared/utils/oneDeskGraphqlFactory";
import ToastService from "primevue/toastservice";
import VueGtag from "vue-gtag-next";
import ConfirmationService from "primevue/confirmationservice";
import Tooltip from 'primevue/tooltip';
import mitt from 'mitt';
import '@/assets/css/styles.scss';
const graphQLProvider = createGraphQLProvider(store);
const eventBus = mitt();
const app = createApp(App)
    .use(store)
    .use(Authenticator, Secrets)
    .use(PrimeVue)
    .directive('tooltip', Tooltip)
    .use(router)
    .provide('eventBus', eventBus)
    .use(ToastService)
    .use(ConfirmationService)
    .use(VueGtag, {
    property: {
        id: "UA-78569407-14",
    },
})
    .provide(DefaultApolloClient, graphQLProvider)
    .mount("#app");
export { app };

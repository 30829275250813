import { ActionTypes } from "./action-types";
import { MutationTypes } from "./mutation-types";
import router from "@/router";
export const actions = {
    [ActionTypes.NAVIGATE_TO](_, name) {
        router.push({ name: name });
    },
    [ActionTypes.SELECT_NAVIGATION_ITEM]({ commit }, name) {
        commit(MutationTypes.SELECT_LINK, name);
    },
    [ActionTypes.SET_INVENTORY_SEARCH]({ commit }, search) {
        commit(MutationTypes.SET_INVENTORY_SEARCH, search);
    },
    [ActionTypes.SET_DISPLAY_FILTERS]({ commit }, display) {
        commit(MutationTypes.SET_DISPLAY_FILTERS, display);
    },
};

import { client } from "@/shared/utils/oneDeskGraphqlFactory";
import { getCatalogCardsByCriteriaPaginatedQuery, getCatalogCardByIdQuery } from "@/graphql/query/catalog-card.query.graphql";
import { updateCatalogCardMutation, createCatalogCardMutation, deleteCatalogCardMutation } from "@/graphql/mutation/catalog-card.mutation.graphql";
const getCatalogCardById = async (input) => {
    try {
        if (!client) {
            throw new Error("Client is not available.");
        }
        const response = await client.query({
            query: getCatalogCardByIdQuery,
            variables: {
                input: input,
            },
            fetchPolicy: "no-cache",
            errorPolicy: "all"
        });
        return response.data.catalogCardById;
    }
    catch (error) {
        console.error(error);
        throw error;
    }
};
const getCatalogCardsByCriteriaPaginated = async (search, pageNumber, pageSize) => {
    try {
        if (!client) {
            throw new Error("Client is not available.");
        }
        const response = await client.query({
            query: getCatalogCardsByCriteriaPaginatedQuery,
            variables: {
                search,
                pageNumber,
                pageSize
            },
            fetchPolicy: "no-cache",
            errorPolicy: "all"
        });
        return response.data.catalogCardsByCriteriaPaginated;
    }
    catch (error) {
        console.error(error);
        throw error;
    }
};
const updateCard = async (input) => {
    try {
        if (!client) {
            throw new Error("Client is not available.");
        }
        const response = await client.mutate({
            mutation: updateCatalogCardMutation,
            variables: {
                input: input
            },
            update: (apolloClientStore, { data }) => {
                return data;
            }
        });
        return response;
    }
    catch (error) {
        console.log(error);
        throw error;
    }
};
const addCatalogCard = async (input) => {
    await upsertCatalogCard(input, createCatalogCardMutation);
};
const updateCatalogCard = async (input) => {
    await upsertCatalogCard(input, updateCatalogCardMutation);
};
const upsertCatalogCard = async (input, mutation) => {
    try {
        if (!client) {
            throw new Error("Client is not available.");
        }
        const response = await client.mutate({
            mutation: mutation,
            variables: {
                input: input
            },
            update: (apolloClientStore, { data }) => {
                return data;
            }
        });
        return response;
    }
    catch (error) {
        console.log(error);
        throw error;
    }
};
const deleteCatalogCard = async (catalogCardId) => {
    try {
        if (!client) {
            throw new Error("Client is not available.");
        }
        const response = await client.mutate({
            mutation: deleteCatalogCardMutation,
            variables: {
                catalogCardId
            }
        });
        return response;
    }
    catch (error) {
        console.log(error);
        throw error;
    }
};
export { getCatalogCardsByCriteriaPaginated, updateCard, updateCatalogCard, addCatalogCard, getCatalogCardById, deleteCatalogCard };

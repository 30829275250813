import { MutationTypes } from "@/store/modules/reference-values/mutation-types";
import { getReferenceValuesDataQuery } from "@/repositories/reference-values.repository";
export const mutations = {
    [MutationTypes.SET_REFERENCE_VALUES](state, payload) {
        state.referenceValues[payload.cle] = payload.referenceValues;
    },
    [MutationTypes.GET_REFERENCE_VALUES](state, cle) {
        return new Promise((resolve) => {
            if (!state.referenceValues[cle] || state.referenceValues[cle].length === 0) {
                state.referenceValues[cle] = [];
                getReferenceValuesDataQuery(cle).then((result) => {
                    state.referenceValues[cle] = result;
                    resolve(state.referenceValues[cle]);
                });
            }
            else
                resolve(state.referenceValues[cle]);
        });
    },
};

const webSiteColumns = [
    {
        field: "siteName",
        header: "Site Name",
        type: "string",
        hasFilter: false,
        hasSlot: false,
        sortable: true,
    },
    {
        field: "siteFramework",
        header: "Platform",
        type: "string",
        hasFilter: false,
        hasSlot: false,
        sortable: true,
    },
    {
        field: "source",
        header: "Source",
        type: "string",
        hasFilter: false,
        hasSlot: false,
        sortable: true,
    },
    {
        field: "isPublic",
        header: "Visibility",
        type: "string",
        hasFilter: false,
        hasSlot: true,
        sortable: true,
    },
    {
        field: "displayBrand",
        header: "Brand",
        type: "string",
        hasFilter: true,
        hasSlot: false,
        sortable: true,
    },
    {
        field: "displayMarket",
        header: "Market",
        type: "string",
        hasFilter: false,
        hasSlot: true,
        sortable: true,
    },
    {
        field: "updated",
        header: "Updated",
        type: "string",
        hasFilter: false,
        hasSlot: true,
        sortable: true,
    },
    {
        field: "actions",
        header: "Actions",
        type: "string",
        hasSlot: true,
    },
];
export { webSiteColumns };

import { client } from "@/shared/utils/oneDeskGraphqlFactory";
import { getLinksByCriteriaQuery } from "@/graphql/query/links.query.graphql";
import { createLinkMutation, updateLinkMutation, deleteLinkMutation } from "@/graphql/mutation/links.mutation.graphql";
const getLinksByCriteria = async (type, entityId) => {
    try {
        if (!client) {
            throw new Error("Client is not available.");
        }
        const response = await client.query({
            query: getLinksByCriteriaQuery,
            variables: {
                type,
                entityId
            },
            fetchPolicy: "network-only",
        });
        return response.data.linksByCriteria;
    }
    catch (error) {
        console.error(error);
        throw error;
    }
};
const createLink = async (link) => {
    try {
        if (!client) {
            throw new Error("Client is not available.");
        }
        const response = await client.mutate({
            mutation: createLinkMutation,
            variables: {
                link
            },
            fetchPolicy: "network-only",
        });
        return response.data.createLink;
    }
    catch (error) {
        console.error(error);
        throw error;
    }
};
const updateLink = async (link) => {
    try {
        if (!client) {
            throw new Error("Client is not available.");
        }
        const response = await client.mutate({
            mutation: updateLinkMutation,
            variables: {
                link
            },
            fetchPolicy: "network-only",
        });
        return response.data.updateLink;
    }
    catch (error) {
        console.error(error);
        throw error;
    }
};
const deleteLink = async (id) => {
    try {
        if (!client) {
            throw new Error("Client is not available.");
        }
        const response = await client.mutate({
            mutation: deleteLinkMutation,
            variables: {
                id
            },
            fetchPolicy: "network-only",
        });
        return response.data.deleteLink;
    }
    catch (error) {
        console.error(error);
        throw error;
    }
};
export { getLinksByCriteria, createLink, updateLink, deleteLink };

import { client } from "@/shared/utils/oneDeskGraphqlFactory";
import { getWebPageTestTasksByPerimeterAndTypesQuery } from "@/graphql/query/tasks.query.graphql";
import { getResultByTaskIdQuery } from "@/graphql/query/tasksResult.query.graphql";
import { addTaskMutation } from "@/graphql/mutation/tasks.mutation.graphql";
const addTask = async function (task) {
    try {
        if (client) {
            const response = await client.mutate({
                mutation: addTaskMutation,
                variables: {
                    task: task,
                },
                update: (apolloClientStore, { data }) => {
                    return data.addTask.task;
                },
            });
            return response;
        }
    }
    catch (error) {
        console.error(error);
        throw error;
    }
};
const getWebPageTestTasksByPerimeterAndTypes = async (perimeter, perimeterId, taskTypes, search, pageNumber, pageSize) => {
    try {
        if (!client) {
            throw new Error("Client is not available.");
        }
        const response = await client.query({
            query: getWebPageTestTasksByPerimeterAndTypesQuery,
            variables: {
                perimeter,
                perimeterId,
                taskTypes,
                search,
                pageNumber: pageNumber,
                pageSize: pageSize,
            },
            fetchPolicy: "network-only",
        });
        return response.data.webPageTestTasksByPerimeterAndTypes;
    }
    catch (error) {
        console.error(error);
        throw error;
    }
};
const getResultByTaskId = async (taskId) => {
    try {
        if (!client) {
            throw new Error("Client is not available.");
        }
        const response = await client.query({
            query: getResultByTaskIdQuery,
            variables: {
                taskId: taskId,
            },
            fetchPolicy: "cache-first",
            errorPolicy: "all",
        });
        return response.data.resultByTaskId;
    }
    catch (error) {
        console.error(error);
        throw error;
    }
};
export { addTask, getWebPageTestTasksByPerimeterAndTypes, getResultByTaskId };

import { createRouter, createWebHistory } from "vue-router";
import LoginView from "../views/LoginView.vue";
import HomeView from "../views/HomeView.vue";
import ErrorPageView from "../views/ErrorPageView.vue";
import InventoryView from "../views/InventoryView.vue";
import BackOfficeCatalogView from "../views/BackOffice/BackOfficeCatalogView.vue";
import BackOfficeInventoryView from "../views/BackOffice/BackOfficeInventoryView.vue";
import WebsiteDetails from "../views/WebsiteDetails.vue";
import { store } from "@/store";
import WPTemplate from "@/views/WebPageTests/WPTemplate.vue";
import OneDeskFooter from "@/components/atoms/OneDeskFooter.vue";
import CardDetailsPage from "@/components/templates/Catalog/CardDetailsPage.vue";
import { AllActionTypes } from "@/store/action-types";
import { trackRouter } from "vue-gtag-next";
import AppTopbar from "@/components/templates/Layout/AppTopbar.vue";
import AdminCatalogServiceList from "@/components/templates/BackOffice/pages/catalog/AdminCatalogServiceList.vue";
import AdminCatalogCategoryList from "@/components/templates/BackOffice/pages/catalog/AdminCatalogCategoryList.vue";
import AdminCatalogCardList from "@/components/templates/BackOffice/pages/catalog/AdminCatalogCardList.vue";
import BackOfficeInventory from "@/components/templates/BackOffice/pages/inventory/BackOfficeInventory.vue";
import BackOfficeBrands from "@/components/templates/BackOffice/pages/inventory/BackOfficeBrands.vue";
import BackOfficeMarkets from "@/components/templates/BackOffice/pages/inventory/BackOfficeMarkets.vue";
import BackOfficeUsers from "@/components/templates/BackOffice/pages/users/BackOfficeUsers.vue";
import BackOfficeAuthorizations from "@/components/templates/BackOffice/pages/users/BackOfficeAuthorizations.vue";
import BackOfficeUsersView from "@/views/BackOffice/BackOfficeUsersView.vue";
import AddWebSite from "@/components/templates/BackOffice/forms/inventory/AddWebSite.vue";
import AddCatalogCard from "@/components/templates/BackOffice/forms/catalog/AddCatalogCard.vue";
import { hasMorePrivileges, Roles } from "@/entities/user.entity";
import CardDetailsEdit from "@/components/templates/Catalog/CardDetailsEdit.vue";
const routes = [
    {
        path: "/",
        name: "Login",
        components: { default: LoginView },
        meta: { requiresAuth: false },
    },
    {
        path: "/home",
        name: "home",
        components: {
            default: HomeView,
            footer: OneDeskFooter,
            header: AppTopbar,
        },
        meta: { requiresAuth: true },
    },
    {
        path: "/inventory",
        name: "inventory",
        components: {
            default: InventoryView,
            footer: OneDeskFooter,
            header: AppTopbar,
        },
        meta: { requiresAuth: true },
    },
    {
        path: "/back-office/users",
        name: "AdminUsers",
        redirect() {
            return { name: "AdminUserAccounts" };
        },
        components: {
            default: BackOfficeUsersView,
            footer: OneDeskFooter,
            header: AppTopbar,
        },
        children: [
            {
                path: "accounts",
                name: "AdminUserAccounts",
                meta: { requiresAuth: true },
                components: {
                    default: BackOfficeUsers,
                },
            },
            {
                path: "authorizations",
                name: "AdminAuthorizations",
                meta: { requiresAuth: true },
                components: {
                    default: BackOfficeAuthorizations,
                },
            },
        ],
        meta: { requiresAuth: true, requiresAdminRights: Roles.SystemAdmin },
    },
    {
        path: "/back-office/catalog",
        name: "AdminCatalog",
        redirect() {
            return { name: "AdminCatalogServices" };
        },
        components: {
            default: BackOfficeCatalogView,
            footer: OneDeskFooter,
            header: AppTopbar,
        },
        children: [
            {
                path: "services",
                name: "AdminCatalogServices",
                components: { default: AdminCatalogServiceList },
            },
            {
                path: "categories",
                name: "AdminCatalogCategories",
                components: { default: AdminCatalogCategoryList },
            },
            {
                path: "cards",
                name: "AdminCatalogCards",
                components: { default: AdminCatalogCardList },
            },
        ],
        meta: { requiresAuth: true, requiresAdminRights: Roles.CatalogAdmin },
    },
    {
        path: "/back-office/catalog/card/add",
        name: "AddCatalogCard",
        components: {
            default: AddCatalogCard,
            footer: OneDeskFooter,
            header: AppTopbar,
        },
        meta: { requiresAuth: true, requiresAdminRights: Roles.CatalogAdmin },
    },
    {
        path: "/back-office/inventory",
        name: "AdminInventory",
        redirect() {
            return { name: "AdminWebsites" };
        },
        components: {
            default: BackOfficeInventoryView,
            footer: OneDeskFooter,
            header: AppTopbar,
        },
        children: [
            {
                path: "websites/:hasSyncIssues?",
                name: "AdminWebsites",
                components: { default: BackOfficeInventory },
            },
            {
                path: "brands",
                name: "AdminBrands",
                components: { default: BackOfficeBrands },
            },
            {
                path: "markets",
                name: "AdminMarkets",
                components: { default: BackOfficeMarkets },
            },
        ],
        meta: { requiresAuth: true, requiresAdminRights: Roles.SystemAdmin },
    },
    {
        path: "/back-office/inventory/add",
        name: "AddWebSite",
        components: {
            default: AddWebSite,
            footer: OneDeskFooter,
            header: AppTopbar,
        },
        meta: { requiresAuth: true },
    },
    {
        path: "/services-catalog/card/display/:cardId",
        name: "CardDetailsPage",
        components: {
            default: CardDetailsPage,
            footer: OneDeskFooter,
            header: AppTopbar,
        },
        meta: { requiresAuth: true },
    },
    {
        path: "/services-catalog/card/edit/:cardId",
        name: "CardDetailsEdit",
        components: {
            default: CardDetailsEdit,
            footer: OneDeskFooter,
            header: AppTopbar,
        },
        meta: { requiresAuth: true, requiresAdminRights: Roles.CatalogAdmin },
    },
    {
        path: "/website/:slug",
        name: "Website",
        meta: { requiresAuth: true },
        components: {
            default: WebsiteDetails,
            footer: OneDeskFooter,
            header: AppTopbar,
        },
    },
    {
        path: "/wp/",
        name: "WP",
        meta: { requiresAuth: true },
        components: {
            default: WPTemplate,
            footer: OneDeskFooter,
            header: AppTopbar,
        },
        children: [
            {
                path: "load-testing",
                name: "load-testing",
                components: {},
            },
            {
                path: "page-performance",
                name: "page-performance",
                components: {},
            },
            {
                path: "accessibility",
                name: "accessibility",
                components: {},
            },
            {
                path: "seo",
                name: "seo",
                components: {},
            },
        ],
    },
    {
        path: "/error",
        name: "ErrorPage",
        meta: { requiresAuth: true },
        components: {
            default: ErrorPageView,
            footer: OneDeskFooter,
            header: AppTopbar,
        },
        children: [
            {
                path: "401",
                name: "ErrorPage401",
                components: {},
            },
            {
                path: "403",
                name: "ErrorPage403",
                components: {},
            },
            {
                path: "404",
                name: "ErrorPage404",
                components: {},
            },
            {
                path: "500",
                name: "ErrorPage500",
                components: {},
            },
            {
                path: "503",
                name: "ErrorPage503",
                components: {},
            },
        ],
    },
];
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});
const userHasEnoughPrivileges = (requiredPrivilege) => {
    return store.getters.adminRoles.some((r) => hasMorePrivileges(r, requiredPrivilege));
};
// Add Navigation Guards for Authenticated Routes, meta "requiresAuth" is defined on route
router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        if (!store.getters.isAuthentificated) {
            next({
                path: "/",
                query: { redirect: to.fullPath },
            });
        }
        else {
            if (!to.meta.requiresAdminRights || (to.meta.requiresAdminRights && userHasEnoughPrivileges(to.meta.requiresAdminRights))) {
                store.dispatch(AllActionTypes.SELECT_NAVIGATION_ITEM, to.name);
                next();
                window.scrollTo({
                    top: 0,
                    behavior: "auto",
                });
            }
            else {
                store.dispatch(AllActionTypes.SELECT_NAVIGATION_ITEM, "home");
                next({
                    path: "/",
                    query: { redirect: to.fullPath },
                });
            }
        }
    }
    else {
        if (store.getters.isAuthentificated /*&&
  router.routes &&
  (router.routes.path == "/" || router.routes.path == "")*/) {
            next({
                path: "/home",
            });
        }
        else {
            next(); // make sure to always call next()!
        }
    }
});
trackRouter(router);
export default router;

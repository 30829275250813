import { ActionTypes } from "./action-types";
import { MutationTypes } from "./mutation-types";
import { addTask } from "@/repositories/tasks.repository";
export const actions = {
    async [ActionTypes.ADD_TASK]({ commit }, task) {
        const taskInput = {
            taskName: task.taskName,
            targetId: task.targetId,
            taskParams: JSON.stringify(task.taskParams),
            operation: JSON.stringify(task.operation),
            title: task.title,
            text: task.title,
            taskType: task.taskType,
            gcpDataId: task.gcpDataId,
        };
        const addedTask = await addTask(taskInput).then(async (response) => {
            commit(MutationTypes.ADD_TASK, response);
        });
        return addedTask;
    },
};

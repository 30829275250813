import { ActionTypes as rootATypes } from "./modules/root/action-types";
import { ActionTypes as userTypes } from "./modules/user/action-types";
import { ActionTypes as tasksTypes } from "./modules/tasks/action-types";
import { ActionTypes as navigationTypes } from "./modules/navigation/action-types";
import { ActionTypes as referenceValuesTypes } from "./modules/reference-values/action-types";
export const AllActionTypes = {
    ...rootATypes,
    ...userTypes,
    ...tasksTypes,
    ...navigationTypes,
    ...referenceValuesTypes,
};

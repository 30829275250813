import { SearchTarget } from "@/shared/enums/SearchTarget.enum";
export const state = {
    selectedNavLink: "home",
    inventorySearch: {
        searchText: "",
        allWebsitesChecked: false,
        searchTarget: SearchTarget.brand,
        brands: [],
        markets: [],
        searchPerimeter: [SearchTarget.market, SearchTarget.brand, SearchTarget.site],
        hasSyncIssues: false,
    },
    displayFilters: false,
};

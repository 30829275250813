import { MutationTypes } from "./mutation-types";
const defaultState = {
    isAuthentificated: false,
    accessToken: undefined,
    userName: undefined,
    account: {
        id: "",
        firstname: "",
        lastname: "",
        displayname: "",
        accountname: "",
        pictureid: "",
        picture: "",
        adminRoles: [],
    },
};
export const mutations = {
    [MutationTypes.SET_ACCESS_TOKEN](state, payload) {
        state.accessToken = payload;
    },
    [MutationTypes.SET_AUTHENTIFIED](state, payload) {
        state.isAuthentificated = payload;
    },
    [MutationTypes.SET_USERNAME](state, payload) {
        state.userName = payload;
    },
    [MutationTypes.RESET_USER_STATE](state) {
        Object.assign(state, defaultState);
    },
    [MutationTypes.SET_ACCOUNT](state, account) {
        Object.assign(state.account, account);
    },
};

import { createStore } from "vuex";
import root from "./modules/root";
import VuexPersistence from "vuex-persist";
import Cookies from "js-cookie";
const vuexLocal = new VuexPersistence({
    key: "myLocalStore",
    storage: window.localStorage,
    modules: ["tasksModule", "referenceValuesModule"],
});
const vuexCookie = new VuexPersistence({
    key: "cookieStore", // The key to store the state on in the storage provider.
    restoreState: (key) => (Cookies.get(key) ? JSON.parse(Cookies.get(key)) : null),
    saveState: (key, state) => {
        Cookies.set(key, JSON.stringify(state), {
            expires: 1 / 4, // expire in 6 hour
        });
    },
    modules: ["userModule"], //only save user auth. module
});
export const store = createStore({
    ...root,
    plugins: [vuexCookie.plugin, vuexLocal.plugin],
});
